<template>
    <div>
        <h3>New Maintenance Entry</h3>
            <div class="row">
                <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                    <label>Location</label>
                    <select class='form-control' v-model='cdiLocationID' @change="get_assets()">
                        <option v-for='[cdiLocationId, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS' v-bind:key='cdiLocationId' :value='cdiLocationId'>
                            {{ cdiLocation }}
                        </option>
                    </select>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Asset</label>
                    <select class="form-control" v-model="assetID" >
                        <option v-for="[id, asset] in maintenanceLogAssets" v-bind:key="id" :value="id">
                            {{ asset }}
                        </option>
                    </select>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                    <label>Date</label>
                    <DatePicker v-model="maintenanceDate"
                                :input-attributes="{ class: 'form-control' }" />
                </div>
                <div class="col-md-2 col-xs-12 form-group">
                    <label>Start Time</label>
                    <DatePicker v-model="startTime"
                                :input-attributes="{ class: 'form-control'}"
                                :pickTime="true"
                                :format="'MM/DD/YYYY hh:mm'"
                    />
                </div>
                <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                    <label>End Time</label>
                    <DatePicker v-model="endTime"
                                :input-attributes="{ class: 'form-control'}"
                                :pickTime="true"
                                :format="'MM/DD/YYYY hh:mm'"
                    />                </div>
                <div class="col-sm-10 col-xs-12 form-group">
                    <label>Reason</label>
                    <textarea v-model="maintenanceReason" class="form-control"></textarea>
                </div>
            </div>
            <button class="btn btn-default" @click="save_entry">Save Entry</button>
    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store";
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import DatePicker from "@/components/utils/DatePicker";


export default {
    name: "New",
    components: {
        DatePicker
    },
    data() {
        return {
            state: store.state,
            startTime: '',
            maintenanceDate:'',
            endTime: '',
            cdiLocationID: '0',
            assetID: '',
            maintenanceReason: '',
            cache: {
                cdiLocationCache
            },
            maintenanceLogAssets: [
                [0,'Options Not Available']],
        }
    },
    methods: {
        save_entry: function() {
            let options = {
                'params[asset_id]': this.assetID,
                'params[cdi_location_id]': this.cdiLocationID,
                'params[date]': this.maintenanceDate,
                'params[start_time]': this.startTime,
                'params[end_time]': this.endTime,
                'params[reason]': this.maintenanceReason,
            };
            store.apiWithObjectLoad('new_maintenance_entry', options).then(() => {
                this.state.currentView = 'search';
            });
        },
        get_assets: function() {
            let options = {
                'params[cdi_location_id]': this.cdiLocationID
            }
            store.customApi('menu_builder/maintenance_log_assets', options).then(res => this.maintenanceLogAssets = res);
        }

    },
}
</script>

<style scoped>
</style>