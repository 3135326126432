export const maintenanceLogStore = {
    state: {
        name: 'maintenance_log',
        title: 'Maintenance Log',
        titlePlural: 'Maintenance Log',
        nav: {
            base: 'maintenance_log',
            newView: true,
            hideUpload: true,
        },
        search: {
            endPoint: 'maintenance_log',
            formFields: {
                location_id: {
                    val: 1,
                    qsField: '[location_id]',
                    label: 'Location',
                },
                month: {
                    val: new Date().getMonth(),
                    qsField: '[month]',
                    label: 'Month',
                },
                year: {
                    val: new Date().getFullYear(),
                    qsField: '[location_id]',
                    label: 'Location',
                },
                asset_id: {
                    val: '',
                    qsField: '[asset_id]',
                    label: 'Asset Number',
                },
            },
            resultFields: {
                maintenance_log_id: {header: 'Log ID', link: ''},
                employee: {header: 'Employee', link: ''},
                asset: {header: 'Asset', link: ''},
                asset_id: {header:'Asset Number', Link: ''},
                date: {header: 'Date', link: ''},
                reason: {header: 'Reason'},
                start_time: {header: 'Start Time', link: ''},
                end_time: {header: 'End Time', link: ''},
                duration: {header: 'Duration (Minutes)', link: ''},
                file: {header: 'Maintenance Service Record', link: ''}
            },
        },
        object: {
            fields: [],
        }
    }
}